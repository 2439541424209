import React, { useEffect, useState } from 'react';
import { arrayOf, string, number } from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from '../../../util/reactIntl';
import FilterPlain from '../FilterPlain/FilterPlain';
import FilterPopup from '../FilterPopup/FilterPopup';
import { useHistory, useLocation } from 'react-router-dom';
import routeConfiguration from '../../../routing/routeConfiguration';
import { createResourceLocatorString } from '../../../util/routes';
import { AutoSuggestion } from '../../../components';
import schoolsData from '../../../assets/schoolData.json';

import css from './SchoolFilter.module.css';
import { convertToDocumentationFormat, getUserSchools } from '../../../util/dataExtractors';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../ducks/user.duck';
import { isArrayLength } from '../../../util/genericHelpers';

const SchoolFilter = ({
  rootClassName,
  className,
  id,
  name,
  label,
  contentPlacementOffset,
  intl,
  showAsPopup,
  queryParamNames,
  isSchoolFilter,
  ...rest
}) => {
  const history = useHistory();
  const location = useLocation();

  const urlQueryParams = new URLSearchParams(location.search);
  const [selectedDistance, setSelectedDistance] = useState(
    urlQueryParams.get(queryParamNames[0]) || null
  );

  const currentUser = useSelector(selectCurrentUser);
  const preferredSchools = getUserSchools(currentUser);

  const [allSelectedSchools, setAllSelectedSchools] = useState(preferredSchools);

  const handleSelectionChange = selectedOptions => {
    const preferredSchoolsSchema = convertToDocumentationFormat(selectedOptions?.map(o => o.value));
    setAllSelectedSchools(selectedOptions);
    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration(),
        {},
        { pub_preferredSchools: preferredSchoolsSchema, ...urlQueryParams }
      )
    );
  };

  const classes = classNames(
    rootClassName || css.root,
    className,
    isSchoolFilter ? css.schoolFilter : null
  );

  useEffect(() => {
    if (isArrayLength(preferredSchools)) {
      setAllSelectedSchools(preferredSchools);
    }
  }, []);

  const renderAutoSuggestions = (
    <AutoSuggestion
      data={schoolsData}
      initialValues={allSelectedSchools}
      searchKeys={['school_name', 'suburb', 'postcode']}
      onSelectionChange={handleSelectionChange}
      isSchoolFilter={isSchoolFilter}
    />
  );

  return showAsPopup ? (
    <FilterPopup
      className={classes}
      rootClassName={rootClassName}
      isSchoolFilter={isSchoolFilter}
      popupClassName={css.popupSize}
      name={name}
      label={label}
      isSelected={selectedDistance !== null}
      id={`${id}.popup`}
      showAsPopup
      hideReset
      hideApply
      onClear={() => {
        setAllSelectedSchools(null);
      }}
      labelMaxWidth={250}
      contentPlacementOffset={contentPlacementOffset}
      {...rest}
    >
      {renderAutoSuggestions}
    </FilterPopup>
  ) : (
    <FilterPlain
      className={className}
      rootClassName={rootClassName}
      isSchoolFilter={isSchoolFilter}
      label={label}
      isSelected={selectedDistance !== null}
      id={`${id}.plain`}
      liveEdit
      hideReset
      hideApply
      contentPlacementOffset={contentPlacementOffset}
      {...rest}
    >
      <fieldset className={css.fieldPlain}>{renderAutoSuggestions}</fieldset>
    </FilterPlain>
  );
};

SchoolFilter.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  name: string.isRequired,
  queryParamNames: arrayOf(string).isRequired,
  label: string.isRequired,
  contentPlacementOffset: number,
  intl: intlShape.isRequired,
};

export default injectIntl(SchoolFilter);
